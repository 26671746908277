import { action, persist, thunk } from 'easy-peasy';
import { createSubscriptionSession, getPlans, validateReepayVoucher } from '../../api/reepay/reepay-api';
import { createContextStoreWithRuntimeModel } from '../helpers/context-store.helpers';
import { ReepayFormActions, ReepayFormState, ReepayFormStore, ReepayFormThunks } from './reepay-form.types';

const reepayFormActions = (): ReepayFormActions => ({
    setModalOpen: action((state, payload) => {
        state.modalOpen = payload;
    }),
    setCustomerInfo: action((state, payload) => {
        state.customerInfo = payload;
    }),
    setVoucherCode: action((state, payload) => {
        state.voucherCode = payload;
    }),
    setVoucherIsValid: action((state, payload) => {
        state.voucherIsValid = payload;
    }),
    setApiError: action((state, payload) => {
        state.apiError = payload;
    }),
    setAgreements: action((state, payload) => {
        state.agreements = payload;
    }),
    setSelectedAgreement: action((state, payload) => {
        state.selectedAgreement = payload;
    }),
    setSelectedPaymentPlan: action((state, payload) => {
        state.selectedPaymentPlan = payload;
    }),
    setPaymentSessionId: action((state, payload) => {
        state.paymentSessionId = payload;
    }),
    setReepaySubscription: action((state, payload) => {
        state.reepaySubscription = payload;
    }),
    setReceiptUrl: action((state, payload) => {
        state.receiptUrl = payload;
    }),
    resetStore: action((state, payload) => {
        if (payload.generateReceipt) {
            state.receiptInfo = {
                customerInfo: state.customerInfo,
                reepaySubscription: state.reepaySubscription,
                selectedAgreement: state.selectedAgreement,
            };

            state.selectedAgreement = undefined;
            state.selectedPaymentPlan = undefined;
            state.paymentSessionId = undefined;
            state.reepaySubscription = undefined;
            state.voucherCode = '';
            state.voucherIsValid = false;
            state.apiError = undefined;
        }

        state.customerInfo = { fullName: '', email: '', address: '', phone: '', postalCode: '', company: '', city: '' };
    }),
    fullyResetStore: action((state) => {
        state.modalOpen = false;
        state.customerInfo = {
            fullName: '',
            email: '',
            address: '',
            phone: '',
            postalCode: '',
            company: '',
            city: '',
        };
        state.receiptInfo = undefined;
        state.receiptUrl = undefined;
        state.voucherCode = '';
        state.voucherIsValid = false;
        state.apiError = undefined;
        state.agreements = [];
        state.selectedAgreement = undefined;
        state.selectedPaymentPlan = undefined;
        state.paymentSessionId = undefined;
        state.reepaySubscription = undefined;
    }),
    resetPaymentSessionId: action((state) => {
        state.paymentSessionId = undefined;
    }),
});

const reepayFormThunks = (): ReepayFormThunks => ({
    validateVoucher: thunk(async (actions, { voucher, accountType, planHandle }): Promise<boolean> => {
        const [response, error] = await validateReepayVoucher({
            voucher: voucher,
            accountType: accountType,
            planHandle: planHandle,
        });

        if (response && !error) {
            actions.setVoucherIsValid(true);
            return true;
        } else {
            actions.setVoucherIsValid(false);
            return false;
        }
    }),
    getPlans: thunk(async (actions, payload) => {
        const allPlans: { key: string; value: string }[] = [];
        payload.forEach((x) => {
            x.paymentPlans.forEach((plan) => {
                allPlans.push({
                    key: plan.planId,
                    value: x.reepayAccountId,
                });
            });
        });
        const [response, error] = await getPlans({
            plans: allPlans,
        });

        const updatedAgreements = payload.map((x) => {
            const updatedPlans = x.paymentPlans.map((pp) => {
                const newAmount = response?.plans.find((y) => y.handle === pp.planId)?.amount ?? 0;
                return {
                    ...pp,
                    amount: newAmount,
                };
            });
            return {
                ...x,
                paymentPlans: updatedPlans,
            };
        });

        if (response && !error) {
            actions.setAgreements(updatedAgreements);
        }
    }),
    createSubscriptionSession: thunk(async (actions, payload) => {
        const [response, error] = await createSubscriptionSession(payload);
        if (response && !error) {
            actions.setPaymentSessionId(response.id);
        } else if (error) {
            actions.setApiError(error);
        }
    }),
});

export const ReepayStore = createContextStoreWithRuntimeModel<ReepayFormStore, Partial<ReepayFormState>>(
    (runtimeModel) =>
        persist(
            {
                modalOpen: runtimeModel?.modalOpen ?? false,
                customerInfo: runtimeModel?.customerInfo ?? {
                    fullName: '',
                    email: '',
                    address: '',
                    phone: '',
                    postalCode: '',
                    company: '',
                    city: '',
                },
                receiptUrl: undefined,
                voucherCode: runtimeModel?.voucherCode ?? '',
                voucherIsValid: runtimeModel?.voucherIsValid ?? false,
                agreements: runtimeModel?.agreements ?? [],
                apiError: undefined,
                ...reepayFormActions(),
                ...reepayFormThunks(),
            },
            {
                deny: ['modalOpen'],
                storage: 'sessionStorage',
            }
        ),
    { name: 'ReepayStore' }
);
