import { FC, useEffect, useMemo, useState } from 'react';
import { Wrapper, ReceiptText, ReceiptHeader, DownloadLink, ActionWrapper } from './receipt.styled';
import { Button } from '../../../shared';
import { useRouter } from 'next/router';
import { useGTMBookingData } from '../../../../hooks/booking/booking/use-gtm-booking-data';
import { BookingStepsStore } from '../../../../lib/state/booking/steps';
import { getICalUrl } from '../../../../utils/helpers/ical-generator';
import { BookingWorkshopsStore } from '../../../../lib/state/booking/workshops';
import { BookingFormStore } from '../../../../lib/state/booking/form';
import { DateStyle, formatDate, formInputValue, getEndTime, vehicleName } from '../../../../utils/helpers';
import { generateFormId } from '../../../../lib/state/booking/form/booking-form.helpers';
import { umbraco } from '../../../../lib/api';
import { DeliveryType } from '../../../../lib';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';
import { PlusSiteProvider } from '../../../plus-sites-shared/reepay/plus-site-provider';
import { BenefitAgreementPromotionBanner } from './benefit-agreement-promotion';
import { CustomerSavingsContent } from '../../../../lib/api/models/umbraco';
import { BookingReceiptPlusSiteSettings } from '../../../../lib/state/booking/receipt/booking-receipt.types';
import { BookingReceiptStore } from '../../../../lib/state/booking/receipt';

type Props = {
    content: umbraco.BookingStepReceipt;
    stepNumber: number;
    siteSettings: BookingReceiptPlusSiteSettings;
};

export const BookingReceipt: FC<Props> = ({ content, stepNumber, siteSettings }) => {
    const router = useRouter();

    const [customerInfo, setCustomerInfo] = useState({
        email: '',
        name: '',
    });

    const { inputs, yourCarInputs, vehicleNameText, customerHasBenefitAgreement } = BookingFormStore.useStoreState((state) => ({
        vehicleNameText:
            state.vehicle?.makeId && state.vehicle.model && state.vehicle.variant
                ? vehicleName(state.vehicle?.makeId, state.vehicle?.model, state.vehicle?.variant)
                : 'unknown',
        inputs: state.inputs.filter(({ step }) => step === 'ContactInfo'),
        yourCarInputs: state.inputs.filter(({ step }) => step === 'YourCar'),
        customerHasBenefitAgreement: state.customer?.benefitAgreement !== null,
    }));

    const { subscriptionPage } = BookingReceiptStore.useStoreState((state) => state);

    const { currentStep } = BookingStepsStore.useStoreState((state) => state);

    const reepayValidationState = {
        customerInfo: {
            address: true,
            city: true,
            email: true,
            fullName: true,
            phone: true,
            postalCode: true,
        },
        voucherCode: true,
    };

    useEffect(() => {
        const emailInput = yourCarInputs.find((input) => input.type === 'email');
        const nameInput = inputs.find((x) => x.id === generateFormId('ContactInfo', 'Name'));

        if (emailInput && nameInput) {
            setCustomerInfo({
                email: formInputValue(emailInput),
                name: formInputValue(nameInput),
            });
        }
        // Dependency array is left empty intentionally to avoid an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { workshops, selectedWorkshop, selectedTimeSlot, selectedTimeSlotToStay, deliveryType } = BookingWorkshopsStore.useStoreState(
        ({ workshops, selectedWorkshop, selectedTimeSlot, selectedTimeSlotToStay, deliveryType }) => ({
            workshops,
            selectedWorkshop,
            selectedTimeSlot,
            selectedTimeSlotToStay,
            deliveryType,
        })
    );

    const workshop = useMemo(() => workshops.find((x) => x.sabId === selectedWorkshop), [selectedWorkshop, workshops]);
    const iCalUrl = useMemo(() => {
        if (selectedTimeSlot) {
            return getICalUrl({
                carModel: vehicleNameText,
                serviceName: 'Mød venligst op 15 minutter før tid',
                startTime:
                    deliveryType === DeliveryType.SelfDeliverAndPickup
                        ? selectedTimeSlot.startDateTime
                        : selectedTimeSlotToStay?.startDateTime ?? new Date(),
                endTime:
                    deliveryType === DeliveryType.SelfDeliverAndPickup
                        ? getEndTime(selectedTimeSlot?.endDateTime)
                        : selectedTimeSlotToStay?.endDateTime ?? new Date(),
                workshop: workshop?.name || '',
                workshopAddress: `${workshop?.address}, ${workshop?.postalCode}`,
            });
        }
    }, [
        deliveryType,
        selectedTimeSlot,
        selectedTimeSlotToStay?.endDateTime,
        selectedTimeSlotToStay?.startDateTime,
        vehicleNameText,
        workshop?.address,
        workshop?.name,
        workshop?.postalCode,
    ]);

    const getContentHtml = (text: string, template: umbraco.ReceiptPlaceholder, value: string, appendix = '') => {
        return text.replace(template, `<strong>${value}</strong>${appendix}`);
    };

    const createMarkup = (info: umbraco.BookingStepReceipt) => {
        const textToProcess = deliveryType !== DeliveryType.ByVendor ? `${info.pickupTimeText} ${info.text}` : info.text;

        let result = getContentHtml(textToProcess, umbraco.ReceiptPlaceholder.Workshop, workshop?.name ?? '');

        const dateToBook =
            deliveryType === DeliveryType.SelfDeliverAndPickup ? selectedTimeSlot?.startDateTime : selectedTimeSlotToStay?.startDateTime;

        if (dateToBook) {
            result = getContentHtml(result, umbraco.ReceiptPlaceholder.Date, formatDate(dateToBook, DateStyle.dk_full_text_hh_mm));
        }

        // Pick up date related handling
        const licensePlateInput = yourCarInputs.find(({ id }) => id === generateFormId('YourCar', 'Car Registration Number'));
        const licensePlate = licensePlateInput ? formInputValue(licensePlateInput) : '';
        result = getContentHtml(result, umbraco.ReceiptPlaceholder.Car, vehicleNameText, ` (${licensePlate})`);
        result = getContentHtml(result, umbraco.ReceiptPlaceholder.ShowUpTime, content.showUpTimeText);
        result = getContentHtml(result, umbraco.ReceiptPlaceholder.Email, customerInfo.email);

        // Other information
        result = result.replace(
            umbraco.ReceiptPlaceholder.SupportTel,
            `<Link><a href=${`tel:${content.supportTel?.split(' ').join('')}`}>${content.supportTel}</a></Link>`
        );
        result = result.replace(
            umbraco.ReceiptPlaceholder.SupportEmail,
            `<Link><a href=${`mailto:${content.supportEmail}`}>${content.supportEmail}</a></Link>`
        );
        result = result.replace(
            umbraco.ReceiptPlaceholder.AddBooking,
            deliveryType !== DeliveryType.ByVendor
                ? `<Link><a href=${iCalUrl} download="Ejner Hessel: Værkstedsbesøg for ${vehicleNameText}.ics">${content.addBookingToCalendarText}</a></Link>`
                : ''
        );
        result = result.split(umbraco.ReceiptPlaceholder.NewLine).join('<br />');

        return { __html: result };
    };

    const { trackBooking } = useGtmTracking();
    const tracker = trackBooking();
    const gtmData = useGTMBookingData();

    useEffect(() => {
        if (!gtmData) return;

        const products = gtmData?.products;
        const workshop = gtmData?.workshop.name;

        if (currentStep === stepNumber && products && workshop) {
            tracker.purchase(workshop, gtmData.revenue, gtmData.tax, gtmData.bookingAppointmentReference + '', products, customerHasBenefitAgreement);
            tracker.customerAndBenefitAgreement(customerHasBenefitAgreement);
        }
    }, [currentStep, customerHasBenefitAgreement, gtmData, stepNumber, tracker]);

    const updatedSiteSettings = useMemo(() => {
        if (!subscriptionPage?.reepayFormContent || !subscriptionPage?.serviceComparison) return null;
        const marketSettings = {
            ...siteSettings?.marketSettings[0],
            reepaySettings: {
                reepayFormContent: subscriptionPage.reepayFormContent,
                serviceComparison: subscriptionPage.serviceComparison,
            },
        };

        return {
            ...siteSettings,
            marketSettings: [marketSettings],
        };
    }, [subscriptionPage?.reepayFormContent, subscriptionPage?.serviceComparison, siteSettings]);

    return (
        <Wrapper>
            {updatedSiteSettings && subscriptionPage && content.benefitAgreements && !customerHasBenefitAgreement && (
                <PlusSiteProvider
                    siteSettings={updatedSiteSettings}
                    page={subscriptionPage}
                    initialValidationState={reepayValidationState}
                    customerSavings={{} as CustomerSavingsContent}
                    isFromBooking={true}
                >
                    <BenefitAgreementPromotionBanner />
                </PlusSiteProvider>
            )}

            <ReceiptHeader>{content.receiptHeader.replace(umbraco.ReceiptPlaceholder.Name, customerInfo.name)}</ReceiptHeader>
            <ReceiptText dangerouslySetInnerHTML={createMarkup(content)} />
            <ActionWrapper>
                {selectedTimeSlot?.startDateTime && deliveryType !== DeliveryType.ByVendor && (
                    <Button variant="primary" onClick={() => null}>
                        <DownloadLink href={iCalUrl} download={`Ejner Hessel: Værkstedsbesøg for ${vehicleNameText}.ics`}>
                            {content.downloadBookingButtonText}
                        </DownloadLink>
                    </Button>
                )}
                <Button
                    variant="link"
                    onClick={() => {
                        if (content.submitButtonLink.target) {
                            window.open(content.submitButtonLink.url);
                        } else {
                            router.push(content.submitButtonLink.url);
                        }
                    }}
                >
                    {content.submitButtonText}
                </Button>
            </ActionWrapper>
        </Wrapper>
    );
};
